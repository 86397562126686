Handlebars.registerPartial('partials/uitk/checkout-bar', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"amount") || (depth0 != null ? lookupProperty(depth0,"amount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"amount","hash":{},"data":data,"loc":{"start":{"line":6,"column":71},"end":{"line":6,"column":81}}}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    return "0";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18nCurrency")||(depth0 && lookupProperty(depth0,"i18nCurrency"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"amount") : depth0),{"name":"i18nCurrency","hash":{"code":(depth0 != null ? lookupProperty(depth0,"code") : depth0)},"data":data,"loc":{"start":{"line":6,"column":113},"end":{"line":6,"column":146}}}));
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18nCurrency")||(depth0 && lookupProperty(depth0,"i18nCurrency"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),0,{"name":"i18nCurrency","hash":{"code":(depth0 != null ? lookupProperty(depth0,"code") : depth0)},"data":data,"loc":{"start":{"line":6,"column":154},"end":{"line":6,"column":182}}}));
},"9":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"confirmLabel") : depth0),{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":10,"column":64},"end":{"line":10,"column":88}}}));
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"uitk_book",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":10,"column":96},"end":{"line":10,"column":119}}}));
},"15":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"saveLabel") : depth0),{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":13,"column":61},"end":{"line":13,"column":82}}}));
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"uitk_save",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":13,"column":90},"end":{"line":13,"column":113}}}));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"checkout-bar\" data-control=\"checkout-bar\">\n    <div class=\"site-content\">\n        <div class=\"row\">\n            <div class=\"col col-4\">\n                <h5 class=\"checkout-bar-title sub-header\">"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(alias1,"uitk_total",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":5,"column":58},"end":{"line":5,"column":82}}}))
    + "</h5>\n                <h1 id=\"checkout-bar-total\" data-amount=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":6,"column":57},"end":{"line":6,"column":97}}})) != null ? stack1 : "")
    + "\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":6,"column":99},"end":{"line":6,"column":189}}})) != null ? stack1 : "")
    + "</h1>\n            </div>\n            <div class=\"col col-8\">\n                <button id=\"checkout-bar-confirm\" class=\"btn btn-action btn-med success "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":88},"end":{"line":9,"column":119}}})) != null ? stack1 : "")
    + "\">\n                    <span class=\"btn-label\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"confirmLabel") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":10,"column":44},"end":{"line":10,"column":126}}})) != null ? stack1 : "")
    + "</span><uitk-icon name=\"arrow90\"></uitk-icon>\n                </button>\n                <button id=\"checkout-bar-save\" class=\"btn btn-text-action btn-med\">\n                    <span class=\"btn-label\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"saveLabel") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":13,"column":44},"end":{"line":13,"column":120}}})) != null ? stack1 : "")
    + "</span>\n                </button>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true}));