Handlebars.registerPartial('partials/uitk/table-total', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tfoot>\n<tr>\n    <td colspan=\"2\" class=\"align-right table-total\">\n        <hr class=\"hr-small hr-right\">\n        <h6 class=\"sub-header\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"uitk_total",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":55}}}))
    + "</h6>\n        <h2 class=\"font-regular\">"
    + alias3((lookupProperty(helpers,"i18nCurrency")||(depth0 && lookupProperty(depth0,"i18nCurrency"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"amount") : depth0),{"name":"i18nCurrency","hash":{"round":(depth0 != null ? lookupProperty(depth0,"round") : depth0),"code":(depth0 != null ? lookupProperty(depth0,"code") : depth0),"locale":(depth0 != null ? lookupProperty(depth0,"locale") : depth0)},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":92}}}))
    + "</h2>\n    </td>\n</tr>\n</tfoot>";
},"useData":true}));