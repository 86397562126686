Handlebars.registerPartial('partials/uitk/replay-tags', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"userId") || (depth0 != null ? lookupProperty(depth0,"userId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"userId","hash":{},"data":data,"loc":{"start":{"line":8,"column":58},"end":{"line":8,"column":68}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":68},"end":{"line":8,"column":97}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return ",";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<!--replayPageName:"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"pageName") : stack1), depth0))
    + "-->\n<!--replaySessionID:"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"sessionId") : stack1), depth0))
    + "-->\n\n<!--replayUserID:"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"user") : stack1)) != null ? lookupProperty(stack1,"userId") : stack1), depth0))
    + "-->\n<!--replayCompanyID:"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"user") : stack1)) != null ? lookupProperty(stack1,"companyId") : stack1), depth0))
    + "-->\n<!--replayProductID:"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"user") : stack1)) != null ? lookupProperty(stack1,"productId") : stack1), depth0))
    + "-->\n<!--replayArrangeeIDs:"
    + ((stack1 = lookupProperty(helpers,"each").call(alias3,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"globalContext") : stack1)) != null ? lookupProperty(stack1,"arrangees") : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":22},"end":{"line":8,"column":106}}})) != null ? stack1 : "")
    + "-->\n<!--replayAgentCode:"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"agent") : stack1)) != null ? lookupProperty(stack1,"agentCode") : stack1), depth0))
    + "-->\n<!--replayTripID:"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"globalContext") : stack1)) != null ? lookupProperty(stack1,"tripId") : stack1), depth0))
    + "-->\n\n<!--replayAuthenticatedUserID:"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"authenticatedEntity") : stack1)) != null ? lookupProperty(stack1,"userId") : stack1), depth0))
    + "-->\n<!--replayUserRoleType:"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"user") : stack1)) != null ? lookupProperty(stack1,"roleType") : stack1), depth0))
    + "-->\n<!--replayCurrentLocale:"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"currentLocale") : stack1), depth0))
    + "-->\n\n<!--replayEgenciaAbTestIds:"
    + alias2(((helper = (helper = lookupProperty(helpers,"egenciaAbTestIds") || (depth0 != null ? lookupProperty(depth0,"egenciaAbTestIds") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"egenciaAbTestIds","hash":{},"data":data,"loc":{"start":{"line":16,"column":27},"end":{"line":16,"column":47}}}) : helper)))
    + "-->";
},"useData":true}));