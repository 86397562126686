Handlebars.registerPartial('partials/uitk/agent-toolbar', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div style=\"display: none;\">\n    <form name=\"toolbar\">\n        <input type=\"hidden\" id=\"tuid\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"tuid") || (depth0 != null ? lookupProperty(depth0,"tuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tuid","hash":{},"data":data,"loc":{"start":{"line":3,"column":46},"end":{"line":3,"column":54}}}) : helper)))
    + "\">\n        <input type=\"hidden\" id=\"rgtuid\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"rgtuid") || (depth0 != null ? lookupProperty(depth0,"rgtuid") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"rgtuid","hash":{},"data":data,"loc":{"start":{"line":4,"column":48},"end":{"line":4,"column":58}}}) : helper)))
    + "\">\n    </form>\n</div>";
},"useData":true}));