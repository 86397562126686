(function (Handlebars) {
    const userOffset = -(new Date().getTimezoneOffset()); //js reports offset as to GMT in minutes rather than how people usually write it (eg CET is reported -120 but typically written +2)
    
    function convertDate(date, sourceOffset = userOffset, destinationOffset = userOffset) {

        const offset = destinationOffset - sourceOffset;
        return new Date(date.getTime() + (offset * 60 * 1000));
    }

    function i18nDate(date, options) {
        if (!date) {
            return '';
        }

        const transformedDate = convertDate(date, options.hash.sourceOffset, options.hash.destinationOffset);

        return uitk.i18n.date(
            transformedDate.getUTCFullYear(),
            transformedDate.getUTCMonth() + 1,
            transformedDate.getUTCDate(),
            options.hash
        );
    }

    function i18nTime(date, options) {
        if (!date) {
            return '';
        }

        const transformedDate = convertDate(date, options.hash.sourceOffset, options.hash.destinationOffset);

        return uitk.i18n.time(
            transformedDate.getUTCHours(),
            transformedDate.getUTCMinutes(),
            options.hash
        );
    }

    /**
     * Takes in minutes, returns string like "1h 29m" or "0h 2m"
     */
    Handlebars.registerHelper('duration', function () {
        var value = arguments[0];
        var options = arguments[--arguments.length],
            duration = uitk.i18n.duration(value, options.hash);
        return duration;
    });


    /**
     * Gets translated message. Supports args:
     * {{i18nMsg "key" "first arg" "second arg"}}
     */
    Handlebars.registerHelper('i18nMsg', function () {
        var message = uitk.i18n.msg.apply(this, arguments);
        return new Handlebars.SafeString(message);
    });


    /**
     * Converts date to localized date format.
     */

    Handlebars.registerHelper('i18nDate', i18nDate);

    /**
     * Converts time to localized time format.
     */
    Handlebars.registerHelper('i18nTime', i18nTime);


    /**
     * Converts number to localized number format.
     */
    Handlebars.registerHelper('i18nNumber', function (number, options) {
        options = options || {};
        return uitk.i18n.number(number, options.hash);
    });

    /**
     * Converts amount to localized currency format.
     */
    Handlebars.registerHelper('i18nCurrency', function (amount, options) {
        options = options || {};
        return uitk.i18n.currency(amount, options.hash);
    });


    /**
     * Returns the native url for invoking native app actions (for use when the page is being used inside a WebView)
     */
    Handlebars.registerHelper('actionMapper', function (options) {
        var url = options.hash.url || '';
        var action = options.hash.action;
        var mappedAction = '';

        // Should map to a native action
        if (action && uitk.adapt.webView) {
            mappedAction = uitk.adapt.getNativeActionUrl(action) + url.substring(url.indexOf('?')); // Appends any query string from the url
        } else {
            mappedAction = url;
        }

        return 'href="' + mappedAction + '"';
    });

    /**
     * Returns a string with random characters
     */
    Handlebars.registerHelper('randomString', function () {
        return Math.random().toString(36).substr(2, 5);
    })
})(Handlebars);