Handlebars.registerPartial('partials/uitk/header', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"rendered_header") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":27,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"rendered_header") || (depth0 != null ? lookupProperty(depth0,"rendered_header") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"rendered_header","hash":{},"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":3,"column":29}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div id=\"global-header\">\n            <div class=\"site-content\">\n                <div class=\"site-navigation\">\n                    <div id=\"site-logo\">\n                        <a id=\"primary-nav-home\" href=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"home") : depth0)) != null ? lookupProperty(stack1,"url") : stack1), depth0))
    + "\" class=\"first\">\n                            <span class=\"visuallyhidden\">Egencia</span>\n                        </a>\n                    </div>\n                    <a id=\"skip-link\" href=\"javascript:void(0)\">Skip to main content</a>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/primary-nav"),depth0,{"name":"partials/uitk/header/primary-nav","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n                    <div id=\"site-utilities\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"globalSearch") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":24},"end":{"line":19,"column":31}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/account-nav"),depth0,{"name":"partials/uitk/header/account-nav","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n            <a id=\"maincontent\" tabIndex=\"-1\"></a>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                            <a id=\"global-search\" class=\"left nav-item\" href=\"#\" data-control=\"modal\" data-modal-id=\"global-search\"><uitk-icon name=\"searchalt\"></uitk-icon></a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"uitk") : depth0)) != null ? lookupProperty(stack1,"adapt") : stack1)) != null ? lookupProperty(stack1,"webView") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":28,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true}));