Handlebars.registerPartial('partials/uitk/core-js', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "   "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-pendo"},"data":data,"loc":{"start":{"line":14,"column":3},"end":{"line":14,"column":81}}})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"ie-polyfill"},"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":18,"column":83}}})) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"intl-shim2021"},"data":data,"loc":{"start":{"line":21,"column":4},"end":{"line":21,"column":85}}})) != null ? stack1 : "")
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-jquery"},"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":24,"column":83}}})) != null ? stack1 : "")
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-zepto"},"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":26,"column":82}}})) != null ? stack1 : "")
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-core"},"data":data,"loc":{"start":{"line":34,"column":4},"end":{"line":34,"column":81}}})) != null ? stack1 : "")
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-core-2"},"data":data,"loc":{"start":{"line":37,"column":4},"end":{"line":37,"column":83}}})) != null ? stack1 : "")
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-libs"},"data":data,"loc":{"start":{"line":43,"column":4},"end":{"line":43,"column":81}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sliderFix") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":4},"end":{"line":46,"column":11}}})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-slider"},"data":data,"loc":{"start":{"line":45,"column":8},"end":{"line":45,"column":87}}})) != null ? stack1 : "")
    + "\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-core-3"},"data":data,"loc":{"start":{"line":55,"column":4},"end":{"line":55,"column":83}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"localization-3"},"data":data,"loc":{"start":{"line":56,"column":4},"end":{"line":56,"column":86}}})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-libs-2"},"data":data,"loc":{"start":{"line":57,"column":4},"end":{"line":57,"column":83}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sliderFix") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":58,"column":4},"end":{"line":60,"column":11}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-slider-2"},"data":data,"loc":{"start":{"line":59,"column":8},"end":{"line":59,"column":89}}})) != null ? stack1 : "")
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"web-vitals"},"data":data,"loc":{"start":{"line":64,"column":4},"end":{"line":64,"column":82}}})) != null ? stack1 : "")
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <script src=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/tags/alert.tag\" type=\"riot/tag\"></script>\n    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-riot"},"data":data,"loc":{"start":{"line":73,"column":4},"end":{"line":73,"column":81}}})) != null ? stack1 : "")
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDevProfile") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(31, data, 0),"data":data,"loc":{"start":{"line":77,"column":4},"end":{"line":91,"column":11}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifEqual")||(depth0 && lookupProperty(depth0,"ifEqual"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"react") : depth0),"true",{"name":"ifEqual","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":78,"column":8},"end":{"line":80,"column":20}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifEqual")||(depth0 && lookupProperty(depth0,"ifEqual"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"react") : depth0),"18",{"name":"ifEqual","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":8},"end":{"line":83,"column":20}}})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-react-dev"},"data":data,"loc":{"start":{"line":79,"column":12},"end":{"line":79,"column":94}}})) != null ? stack1 : "")
    + "\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-react-18-dev"},"data":data,"loc":{"start":{"line":82,"column":12},"end":{"line":82,"column":97}}})) != null ? stack1 : "")
    + "\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifEqual")||(depth0 && lookupProperty(depth0,"ifEqual"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"react") : depth0),"true",{"name":"ifEqual","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":8},"end":{"line":87,"column":20}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifEqual")||(depth0 && lookupProperty(depth0,"ifEqual"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"react") : depth0),"18",{"name":"ifEqual","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":8},"end":{"line":90,"column":20}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-react"},"data":data,"loc":{"start":{"line":86,"column":12},"end":{"line":86,"column":90}}})) != null ? stack1 : "")
    + "\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-react-18"},"data":data,"loc":{"start":{"line":89,"column":12},"end":{"line":89,"column":93}}})) != null ? stack1 : "")
    + "\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-preact"},"data":data,"loc":{"start":{"line":94,"column":4},"end":{"line":94,"column":83}}})) != null ? stack1 : "")
    + "\n";
},"38":function(container,depth0,helpers,partials,data) {
    return "    <script async src='https://cdn.glassboxcdn.com/egencia/detector-dom.min.js' id='_cls_detector' data-clsconfig='reportURI=https://report.egencia.glassboxdigital.io/glassbox/reporting/92567D9E-8C6C-0846-8C2A-C71818A7C0D8/cls_report;valueMaskingMode=whitelist;maskWhitelistValueByClass=replay-safe,replay-reveal;domMaskingMode=blacklist;domBlacklistMaskContentByClass=replay-conceal;recordScrolls=true;recordMouseMoves=true;ccAutoMask=true;ccAutoMaskKeepLastDigits=4'></script>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<script>\n    // Set up EG with EG from server\n    window.EG = "
    + alias3((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"EG") : depth0),{"name":"json","hash":{"escapeHTML":true},"data":data,"loc":{"start":{"line":3,"column":16},"end":{"line":3,"column":43}}}))
    + " || {};\n\n    // Set up uitk with uitk from server (the rest of uitk is merged by uitk.js)\n    window.uitk = "
    + alias3((lookupProperty(helpers,"json")||(depth0 && lookupProperty(depth0,"json"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"uitk") : depth0),{"name":"json","hash":{"escapeHTML":true},"data":data,"loc":{"start":{"line":6,"column":18},"end":{"line":6,"column":47}}}))
    + " || {};\n\n    // Need to set this before any lib tries to do i18n. I18n.js has been modified as a result.\n    var I18n = I18n || {};\n    I18n.locale = EG.currentLocale\n</script>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pendo") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":0},"end":{"line":15,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifEqual")||(depth0 && lookupProperty(depth0,"ifEqual"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"polyfillLevel") : depth0),"InternetExplorer",{"name":"ifEqual","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":0},"end":{"line":19,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifEqual")||(depth0 && lookupProperty(depth0,"ifEqual"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"polyfillLevel") : depth0),"IntlShim2021",{"name":"ifEqual","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":0},"end":{"line":22,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sliderFix") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":23,"column":0},"end":{"line":27,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-required-libs"},"data":data,"loc":{"start":{"line":29,"column":0},"end":{"line":29,"column":86}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-backbone"},"data":data,"loc":{"start":{"line":30,"column":0},"end":{"line":30,"column":81}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-handlebars"},"data":data,"loc":{"start":{"line":31,"column":0},"end":{"line":31,"column":83}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifEqual")||(depth0 && lookupProperty(depth0,"ifEqual"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"localizationVersion") : depth0),1,{"name":"ifEqual","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":0},"end":{"line":35,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifEqual")||(depth0 && lookupProperty(depth0,"ifEqual"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"localizationVersion") : depth0),2,{"name":"ifEqual","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":0},"end":{"line":38,"column":12}}})) != null ? stack1 : "")
    + "\n"
    + alias3((lookupProperty(helpers,"i18nJs")||(depth0 && lookupProperty(depth0,"i18nJs"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"currentLocale") : stack1),{"name":"i18nJs","hash":{"localizationVersion":(depth0 != null ? lookupProperty(depth0,"localizationVersion") : depth0)},"data":data,"loc":{"start":{"line":40,"column":0},"end":{"line":40,"column":68}}}))
    + "\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifEqual")||(depth0 && lookupProperty(depth0,"ifEqual"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"localizationVersion") : depth0),1,{"name":"ifEqual","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":0},"end":{"line":47,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifEqual")||(depth0 && lookupProperty(depth0,"ifEqual"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"localizationVersion") : depth0),2,{"name":"ifEqual","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":48,"column":0},"end":{"line":53,"column":12}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifEqual")||(depth0 && lookupProperty(depth0,"ifEqual"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"localizationVersion") : depth0),3,{"name":"ifEqual","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":0},"end":{"line":61,"column":12}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"webVitalsEnabled") : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":0},"end":{"line":65,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-vendors"},"data":data,"loc":{"start":{"line":66,"column":0},"end":{"line":66,"column":80}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-core-app"},"data":data,"loc":{"start":{"line":67,"column":0},"end":{"line":67,"column":81}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"uitk-analytics"},"data":data,"loc":{"start":{"line":68,"column":0},"end":{"line":68,"column":82}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"includeJsBundle")||(depth0 && lookupProperty(depth0,"includeJsBundle"))||alias2).call(alias1,{"name":"includeJsBundle","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"bundleName":"agent-toolbar"},"data":data,"loc":{"start":{"line":69,"column":0},"end":{"line":69,"column":81}}})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"riotPilot") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":0},"end":{"line":74,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"react") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":0},"end":{"line":92,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"preact") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":0},"end":{"line":95,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"glassbox") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":0},"end":{"line":99,"column":7}}})) != null ? stack1 : "");
},"useData":true}));