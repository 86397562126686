Handlebars.registerPartial('partials/uitk/header/primary-nav', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <a id=\"primary-nav-toggle\"><span>Menu</span></a>\n    <nav id=\"primary-nav-mobile\" class=\"primary-nav\">\n        <div class=\"nav-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"not_logged_in") : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":16,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/support-menu"),depth0,{"name":"partials/uitk/header/support-menu","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <div class=\"nav-panel-close\">\n            <div class=\"icon icon-arrow270\"></div>\n        </div>\n\n        <!-- Submenu Panels -->\n        <div class=\"nav-panel\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"menus") : depth0)) != null ? lookupProperty(stack1,"toolMenuId") : stack1),{"name":"with","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":114,"column":21}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"menus") : depth0)) != null ? lookupProperty(stack1,"adminMenuId") : stack1),{"name":"with","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":12},"end":{"line":128,"column":21}}})) != null ? stack1 : "")
    + "        </div>\n    </nav>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/book-menu"),depth0,{"name":"partials/uitk/header/book-menu","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                <!-- Manage -->\n                <h4 class=\"category-title\">Manage</h4>\n                <ul class=\"submenu submenu-primary list\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/trip-menu"),depth0,{"name":"partials/uitk/header/trip-menu","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/expense-menu"),depth0,{"name":"partials/uitk/header/expense-menu","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/tools-menu"),depth0,{"name":"partials/uitk/header/tools-menu","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/admin-menu"),depth0,{"name":"partials/uitk/header/admin-menu","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/community-menu"),depth0,{"name":"partials/uitk/header/community-menu","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </ul>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":27,"column":25},"end":{"line":27,"column":31}}}) : helper)))
    + "\" class=\"panel-menu-wrapper\">\n                    <h4 class=\"category-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":28,"column":47},"end":{"line":28,"column":56}}}) : helper)))
    + "</h4>\n                    <ul class=\"submenu submenu-secondary multi-list tools-submenu\">\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"with","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":24},"end":{"line":111,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n                </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"generalMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":28},"end":{"line":40,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"expenseMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":41,"column":28},"end":{"line":50,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"travMgmtMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":51,"column":28},"end":{"line":60,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"travPolicyMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":28},"end":{"line":70,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"userMgmtMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":71,"column":28},"end":{"line":80,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"compMgmtMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":28},"end":{"line":90,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"reportingMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":28},"end":{"line":100,"column":35}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"riskManagementMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":28},"end":{"line":110,"column":35}}})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li>\n                                    <a href=\"#menu-general\" data-control=\"toggle\" class=\"toggle icon icon-caret180\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"generalMenuId") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</a>\n                                    <ul id=\"menu-general\" class=\"toggle-pane\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"generalMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":40},"end":{"line":37,"column":49}}})) != null ? stack1 : "")
    + "                                    </ul>\n                                </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                            <li class=\"menu-item\"><a id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"opensNewWindow") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":106},"end":{"line":36,"column":155}}})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</a></li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "target=“_blank”";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li>\n                                    <a href=\"#menu-expense\" data-control=\"toggle\" class=\"toggle icon icon-caret180\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"expenseMenuId") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</a>\n                                    <ul id=\"menu-expense\" class=\"toggle-pane\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"expenseMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":40},"end":{"line":47,"column":49}}})) != null ? stack1 : "")
    + "                                    </ul>\n                                </li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li>\n                                    <a href=\"#menu-travel-mgmt\" data-control=\"toggle\" class=\"toggle icon icon-caret180\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"travMgmtMenuId") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</a>\n                                    <ul id=\"menu-travel-mgmt\" class=\"toggle-pane\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"travMgmtMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":40},"end":{"line":57,"column":49}}})) != null ? stack1 : "")
    + "                                    </ul>\n                                </li>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li>\n                                    <a href=\"#menu-travel-policy\" data-control=\"toggle\" class=\"toggle icon icon-caret180\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"travPolicyMenuId") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</a>\n                                    <ul id=\"menu-travel-policy\" class=\"toggle-pane\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"travPolicyMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":40},"end":{"line":67,"column":49}}})) != null ? stack1 : "")
    + "                                    </ul>\n                                </li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li>\n                                    <a href=\"#menu-user-mgmt\" data-control=\"toggle\" class=\"toggle icon icon-caret180\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"userMgmtMenuId") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</a>\n                                    <ul id=\"menu-user-mgmt\" class=\"toggle-pane\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"userMgmtMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":75,"column":40},"end":{"line":77,"column":49}}})) != null ? stack1 : "")
    + "                                    </ul>\n                                </li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li>\n                                    <a href=\"#menu-comp-mgmt\" data-control=\"toggle\" class=\"toggle icon icon-caret180\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"compMgmtMenuId") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</a>\n                                    <ul id=\"menu-comp-mgmt\" class=\"toggle-pane\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"compMgmtMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":40},"end":{"line":87,"column":49}}})) != null ? stack1 : "")
    + "                                    </ul>\n                                </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li>\n                                    <a href=\"#menu-reporting\" data-control=\"toggle\" class=\"toggle icon icon-caret180\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"reportingMenuId") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</a>\n                                    <ul id=\"menu-reporting\" class=\"toggle-pane\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"reportingMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":40},"end":{"line":97,"column":49}}})) != null ? stack1 : "")
    + "                                    </ul>\n                                </li>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                <li>\n                                    <a href=\"#menu-risk-management\" data-control=\"toggle\" class=\"toggle icon icon-caret180\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"riskManagementMenuId") : depth0)) != null ? lookupProperty(stack1,"label") : stack1), depth0))
    + "</a>\n                                    <ul id=\"menu-risk-management\" class=\"toggle-pane\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"riskManagementMenuId") : depth0)) != null ? lookupProperty(stack1,"items") : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":105,"column":40},"end":{"line":107,"column":49}}})) != null ? stack1 : "")
    + "                                    </ul>\n                                </li>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":118,"column":25},"end":{"line":118,"column":31}}}) : helper)))
    + "\" class=\"panel-menu-wrapper\">\n                    <h4 id=\"primary-nav-admin\" class=\"category-title for-agent-only\"><span><uitk-icon name=\"headset\"></uitk-icon> "
    + alias4(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":119,"column":130},"end":{"line":119,"column":139}}}) : helper)))
    + "</span></h4>\n                    <ul class=\"submenu submenu-secondary list admin-submenu\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":24},"end":{"line":125,"column":33}}})) != null ? stack1 : "")
    + "                    </ul>\n                </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            <li class=\"menu-item\">\n                                <a id=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"id") : depth0), depth0))
    + "\" href=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"url") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"opensNewWindow") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":123,"column":72},"end":{"line":123,"column":121}}})) != null ? stack1 : "")
    + ">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "</a>\n                            </li>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <nav id=\"primary-nav-desktop\" class=\"primary-nav\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"not_logged_in") : depth0),{"name":"unless","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":133,"column":8},"end":{"line":150,"column":19}}})) != null ? stack1 : "")
    + "    </nav>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <ul>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/book-menu"),depth0,{"name":"partials/uitk/header/book-menu","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/trip-menu"),depth0,{"name":"partials/uitk/header/trip-menu","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/expense-menu"),depth0,{"name":"partials/uitk/header/expense-menu","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/tools-menu"),depth0,{"name":"partials/uitk/header/tools-menu","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/admin-menu"),depth0,{"name":"partials/uitk/header/admin-menu","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/community-menu"),depth0,{"name":"partials/uitk/header/community-menu","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </ul>\n            <ul class=\"ul-right\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/support-menu"),depth0,{"name":"partials/uitk/header/support-menu","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </ul>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <ul class=\"ul-right\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/support-menu"),depth0,{"name":"partials/uitk/header/support-menu","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/header/localization-menu"),depth0,{"name":"partials/uitk/header/localization-menu","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </ul>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"uitk") : depth0)) != null ? lookupProperty(stack1,"adapt") : stack1)) != null ? lookupProperty(stack1,"smallScreen") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":152,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true}));