Handlebars.registerPartial('partials/uitk/star-ratings-filter', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return " class=\"visuallyhidden\"";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":6,"column":79},"end":{"line":6,"column":85}}}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    return "star";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data,"loc":{"start":{"line":6,"column":126},"end":{"line":6,"column":134}}}) : helper)));
},"9":function(container,depth0,helpers,partials,data) {
    return "starRating";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<fieldset class=\"star-ratings-filter\">\n	<legend"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"legendHidden") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":58}}})) != null ? stack1 : "")
    + ">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"uitk_rating_stars_legend",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":2,"column":59},"end":{"line":2,"column":97}}}))
    + "</legend>\n\n	<div class=\"star-input-wrapper\">\n		<input type=\"radio\" class=\"star-rating unradio visuallyhidden\" id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":6,"column":69},"end":{"line":6,"column":104}}})) != null ? stack1 : "")
    + "-5\" name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":6,"column":114},"end":{"line":6,"column":159}}})) != null ? stack1 : "")
    + "\" value=\"5\" />\n		<label for=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":7,"column":14},"end":{"line":7,"column":49}}})) != null ? stack1 : "")
    + "-5\" class=\"star-label unlabel\">\n			<span class=\"visuallyhidden\">5 "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"uitk_rating_stars_text",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":8,"column":34},"end":{"line":8,"column":70}}}))
    + "</span>\n		</label>\n\n		<input type=\"radio\" class=\"star-rating unradio visuallyhidden\" id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":11,"column":69},"end":{"line":11,"column":104}}})) != null ? stack1 : "")
    + "-4\" name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":11,"column":114},"end":{"line":11,"column":159}}})) != null ? stack1 : "")
    + "\" value=\"4\" />\n		<label for=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":12,"column":14},"end":{"line":12,"column":49}}})) != null ? stack1 : "")
    + "-4\" class=\"star-label unlabel\">\n			<span class=\"visuallyhidden\">4 "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"uitk_rating_stars_text",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":13,"column":34},"end":{"line":13,"column":70}}}))
    + "</span>\n		</label>\n\n		<input type=\"radio\" class=\"star-rating unradio visuallyhidden\" id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":16,"column":69},"end":{"line":16,"column":104}}})) != null ? stack1 : "")
    + "-3\" name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":16,"column":114},"end":{"line":16,"column":159}}})) != null ? stack1 : "")
    + "\" value=\"3\" />\n		<label for=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":17,"column":14},"end":{"line":17,"column":49}}})) != null ? stack1 : "")
    + "-3\" class=\"star-label unlabel\">\n			<span class=\"visuallyhidden\">3 "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"uitk_rating_stars_text",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":18,"column":34},"end":{"line":18,"column":70}}}))
    + "</span>\n		</label>\n\n		<input type=\"radio\" class=\"star-rating unradio visuallyhidden\" id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":21,"column":69},"end":{"line":21,"column":104}}})) != null ? stack1 : "")
    + "-2\" name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":21,"column":114},"end":{"line":21,"column":159}}})) != null ? stack1 : "")
    + "\" value=\"2\" />\n		<label for=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":22,"column":14},"end":{"line":22,"column":49}}})) != null ? stack1 : "")
    + "-2\" class=\"star-label unlabel\">\n			<span class=\"visuallyhidden\">2 "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"uitk_rating_stars_text",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":23,"column":34},"end":{"line":23,"column":70}}}))
    + "</span>\n		</label>\n\n		<input type=\"radio\" class=\"star-rating unradio visuallyhidden\" id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":26,"column":69},"end":{"line":26,"column":104}}})) != null ? stack1 : "")
    + "-1\" name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":26,"column":114},"end":{"line":26,"column":159}}})) != null ? stack1 : "")
    + "\" value=\"1\" />\n		<label for=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":27,"column":14},"end":{"line":27,"column":49}}})) != null ? stack1 : "")
    + "-1\" class=\"star-label unlabel\">\n			<span class=\"visuallyhidden\">1 "
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"uitk_rating_stars_text",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":28,"column":34},"end":{"line":28,"column":70}}}))
    + "</span>\n		</label>\n\n		<input type=\"radio\" class=\"reset-rating unradio\" id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":31,"column":55},"end":{"line":31,"column":90}}})) != null ? stack1 : "")
    + "-clear\" name=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":31,"column":104},"end":{"line":31,"column":149}}})) != null ? stack1 : "")
    + "\" value=\"0\" checked=\"checked\" />\n		<label for=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"id") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":32,"column":14},"end":{"line":32,"column":49}}})) != null ? stack1 : "")
    + "-clear\" class=\"reset-label unlabel visuallyhidden\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"uitk_filter_reset",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":32,"column":100},"end":{"line":32,"column":131}}}))
    + "\n        </label>\n	</div>\n</fieldset>\n";
},"useData":true}));