Handlebars.registerPartial('partials/uitk/head-tags', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<meta http-equiv=\"X-UA-Compatible\" content=\"IE=edge,chrome=1\">\n<meta charset=\"UTF-8\"/>\n<meta name=\"viewport\" content=\"width=device-width, initial-scale=1.0\"/>\n<meta name=\"description\" content=\""
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"uitk_meta_description",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":4,"column":34},"end":{"line":4,"column":69}}}))
    + "\">\n<meta name=\"theme-color\" content=\"#152835\">\n<meta name=\"robots\" content=\"noindex, nofollow\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/favicon"),depth0,{"name":"partials/uitk/favicon","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/replay-tags"),depth0,{"name":"partials/uitk/replay-tags","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true}));