Handlebars.registerPartial('partials/uitk/core-css', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = (lookupProperty(helpers,"includeLess")||(depth0 && lookupProperty(depth0,"includeLess"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"includeLess","hash":{"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"stylesheet":"uitk-core-eyescream"},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":87}}})) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"includeLess")||(depth0 && lookupProperty(depth0,"includeLess"))||container.hooks.helperMissing).call(alias1,{"name":"includeLess","hash":{"styleVersion":(depth0 != null ? lookupProperty(depth0,"uitkGlobalStyleVersion") : depth0),"assetPath":((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1),"stylesheet":"uitk-core"},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":109}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"eyescream") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":4,"column":7}}})) != null ? stack1 : "");
},"useData":true}));