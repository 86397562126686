Handlebars.registerPartial('partials/uitk/header/localization-menu', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"uitk") : depth0)) != null ? lookupProperty(stack1,"adapt") : stack1)) != null ? lookupProperty(stack1,"smallScreen") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":52,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"localization-menu\">\n            <div id=\"language-menu\" href=\"#language-options\" class=\"h3 toggle language-menu-control\" data-control=\"toggle\" data-trigger=\"hover\">\n                <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"label","hash":{},"data":data,"loc":{"start":{"line":6,"column":22},"end":{"line":6,"column":31}}}) : helper)))
    + "</span>\n                <span class=\"language-menu-identifier\">\n                    <uitk-country-flag class=\"language-menu-flag\" isocode="
    + alias2(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"selected_language") : depth0)) != null ? lookupProperty(stack1,"alpha2_country_code") : stack1), depth0))
    + "></uitk-country-flag>\n                    <uitk-icon name=\"caret180\"></uitk-icon>\n                </span>\n            </div>\n            <ul id=\"language-options\" class=\"toggle-pane language-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"supported_languages") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":23,"column":25}}})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"language-option\">\n                        <a href="
    + alias3((lookupProperty(helpers,"appendQueryParams")||(depth0 && lookupProperty(depth0,"appendQueryParams"))||alias2).call(alias1,{"name":"appendQueryParams","hash":{"queryStringValue":(depth0 != null ? lookupProperty(depth0,"locale") : depth0),"queryStringKey":"egencia_locale"},"data":data,"loc":{"start":{"line":15,"column":32},"end":{"line":15,"column":109}}}))
    + " data-locale=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":15,"column":123},"end":{"line":15,"column":133}}}) : helper)))
    + "\">\n                            <span class=\"language-label\">\n                                <uitk-country-flag class=\"language-menu-flag\" isocode=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"alpha2_country_code") || (depth0 != null ? lookupProperty(depth0,"alpha2_country_code") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"alpha2_country_code","hash":{},"data":data,"loc":{"start":{"line":17,"column":87},"end":{"line":17,"column":110}}}) : helper)))
    + "\"></uitk-country-flag>\n                                <span>"
    + alias3(((helper = (helper = lookupProperty(helpers,"language_code") || (depth0 != null ? lookupProperty(depth0,"language_code") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"language_code","hash":{},"data":data,"loc":{"start":{"line":18,"column":38},"end":{"line":18,"column":55}}}) : helper)))
    + "</span>\n                            </span>\n                            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":28},"end":{"line":20,"column":118}}})) != null ? stack1 : "")
    + "\n                        </a>\n                    </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span>"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"uitk.localization_menu.language_selected",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":20,"column":50},"end":{"line":20,"column":104}}}))
    + "</span>";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"menu-item\">\n            <a id=\"language-menu\" class=\"language-menu-control\" href=\"#localization-menu\" data-control=\"tooltip\" data-js-theme=\"profile\" data-pos=\"br\">\n                <uitk-icon name=\"caret180\"></uitk-icon>\n"
    + ((stack1 = lookupProperty(helpers,"with").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected_language") : depth0),{"name":"with","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":35,"column":25}}})) != null ? stack1 : "")
    + "            </a>\n            <div id=\"localization-menu\" class=\"visuallyhidden\">\n                <ul id=\"language-options\" class=\"list-nobullet language-list\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"supported_languages") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":20},"end":{"line":48,"column":29}}})) != null ? stack1 : "")
    + "                </ul>\n            </div>\n        </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <uitk-country-flag class=\"language-menu-flag\" isocode=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"alpha2_country_code") || (depth0 != null ? lookupProperty(depth0,"alpha2_country_code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"alpha2_country_code","hash":{},"data":data,"loc":{"start":{"line":31,"column":75},"end":{"line":31,"column":98}}}) : helper)))
    + "\"></uitk-country-flag>\n                    <span>"
    + alias4(((helper = (helper = lookupProperty(helpers,"language_code") || (depth0 != null ? lookupProperty(depth0,"language_code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"language_code","hash":{},"data":data,"loc":{"start":{"line":32,"column":26},"end":{"line":32,"column":43}}}) : helper)))
    + "</span>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"label") || (depth0 != null ? lookupProperty(depth0,"label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"label","hash":{},"data":data,"loc":{"start":{"line":34,"column":26},"end":{"line":34,"column":35}}}) : helper)))
    + "</span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"language-option"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":50},"end":{"line":40,"column":82}}})) != null ? stack1 : "")
    + "\">\n                            <a href="
    + alias3((lookupProperty(helpers,"appendQueryParams")||(depth0 && lookupProperty(depth0,"appendQueryParams"))||alias2).call(alias1,{"name":"appendQueryParams","hash":{"queryStringValue":(depth0 != null ? lookupProperty(depth0,"locale") : depth0),"queryStringKey":"egencia_locale"},"data":data,"loc":{"start":{"line":41,"column":36},"end":{"line":41,"column":113}}}))
    + " data-locale=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"locale") || (depth0 != null ? lookupProperty(depth0,"locale") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"locale","hash":{},"data":data,"loc":{"start":{"line":41,"column":127},"end":{"line":41,"column":137}}}) : helper)))
    + "\">\n                                <span class=\"language-label\">\n                                    <uitk-country-flag class=\"language-menu-flag\" isocode=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"alpha2_country_code") || (depth0 != null ? lookupProperty(depth0,"alpha2_country_code") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"alpha2_country_code","hash":{},"data":data,"loc":{"start":{"line":43,"column":91},"end":{"line":43,"column":114}}}) : helper)))
    + "\"></uitk-country-flag>\n                                    <span>"
    + alias3(((helper = (helper = lookupProperty(helpers,"language_code") || (depth0 != null ? lookupProperty(depth0,"language_code") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"language_code","hash":{},"data":data,"loc":{"start":{"line":44,"column":42},"end":{"line":44,"column":59}}}) : helper)))
    + "</span>\n                                </span>\n                            </a>\n                        </li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return " selected";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"with").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"localization_menu") : depth0),{"name":"with","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":53,"column":9}}})) != null ? stack1 : "");
},"useData":true}));