Handlebars.registerPartial('partials/uitk/calendar', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"startDateId") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":52,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"endDateId") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":51,"column":15}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"date-range "
    + alias4(((helper = (helper = lookupProperty(helpers,"classes") || (depth0 != null ? lookupProperty(depth0,"classes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":4,"column":35},"end":{"line":4,"column":46}}}) : helper)))
    + "\">\n                <div class=\"input-group "
    + alias4(((helper = (helper = lookupProperty(helpers,"classes") || (depth0 != null ? lookupProperty(depth0,"classes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":51}}}) : helper)))
    + " input-icon-left"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"startLabel") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":67},"end":{"line":5,"column":106}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"startLabel") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":20},"end":{"line":8,"column":27}}})) != null ? stack1 : "")
    + "                    <input id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"startDateId") || (depth0 != null ? lookupProperty(depth0,"startDateId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startDateId","hash":{},"data":data,"loc":{"start":{"line":9,"column":31},"end":{"line":9,"column":46}}}) : helper)))
    + "\" type=\"text\" class=\"cal-text-input "
    + alias4(((helper = (helper = lookupProperty(helpers,"inputClasses") || (depth0 != null ? lookupProperty(depth0,"inputClasses") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputClasses","hash":{},"data":data,"loc":{"start":{"line":9,"column":82},"end":{"line":9,"column":98}}}) : helper)))
    + "\" size=\"12\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":9,"column":116},"end":{"line":9,"column":124}}}) : helper)))
    + "_start\" placeholder=\""
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"uitk_calendar_placeholder_date",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":9,"column":145},"end":{"line":9,"column":189}}}))
    + "\"\n                        value=\""
    + alias4((lookupProperty(helpers,"i18nDate")||(depth0 && lookupProperty(depth0,"i18nDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"startDateValue") : depth0),{"name":"i18nDate","hash":{"style":"short"},"data":data,"loc":{"start":{"line":10,"column":31},"end":{"line":10,"column":72}}}))
    + "\"\n                        data-iso-date=\""
    + alias4((lookupProperty(helpers,"i18nDate")||(depth0 && lookupProperty(depth0,"i18nDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"startDateValue") : depth0),{"name":"i18nDate","hash":{"style":"iso"},"data":data,"loc":{"start":{"line":11,"column":39},"end":{"line":11,"column":78}}}))
    + "\"\n                        data-control=\"calendar\"\n                        data-first-valid-date=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"firstValidDate") || (depth0 != null ? lookupProperty(depth0,"firstValidDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstValidDate","hash":{},"data":data,"loc":{"start":{"line":13,"column":47},"end":{"line":13,"column":65}}}) : helper)))
    + "\"\n                        data-last-valid-date=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"lastValidDate") || (depth0 != null ? lookupProperty(depth0,"lastValidDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastValidDate","hash":{},"data":data,"loc":{"start":{"line":14,"column":46},"end":{"line":14,"column":63}}}) : helper)))
    + "\"\n                        data-date-range=\"true\"\n                        data-end-date=\"#"
    + alias4(((helper = (helper = lookupProperty(helpers,"endDateId") || (depth0 != null ? lookupProperty(depth0,"endDateId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"endDateId","hash":{},"data":data,"loc":{"start":{"line":16,"column":40},"end":{"line":16,"column":53}}}) : helper)))
    + "\"\n                        data-default-booking-length=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bookingLength") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":17,"column":53},"end":{"line":17,"column":107}}})) != null ? stack1 : "")
    + "\"\n                        data-max-booking-length=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"maxBookingLength") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":18,"column":49},"end":{"line":18,"column":110}}})) != null ? stack1 : "")
    + "\"\n                        data-min-booking-length=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"minBookingLength") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":19,"column":49},"end":{"line":19,"column":109}}})) != null ? stack1 : "")
    + "\"\n                        data-annual=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"annual") || (depth0 != null ? lookupProperty(depth0,"annual") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"annual","hash":{},"data":data,"loc":{"start":{"line":20,"column":37},"end":{"line":20,"column":47}}}) : helper)))
    + "\"\n                        data-time-picker-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"timePickerId") || (depth0 != null ? lookupProperty(depth0,"timePickerId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"timePickerId","hash":{},"data":data,"loc":{"start":{"line":21,"column":45},"end":{"line":21,"column":61}}}) : helper)))
    + "\"\n                        data-tooltip-id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tooltipId") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":22,"column":41},"end":{"line":22,"column":101}}})) != null ? stack1 : "")
    + "\"\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"smallScreen") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":24},"end":{"line":23,"column":59}}})) != null ? stack1 : "")
    + "\n                        autocomplete=\"off\"/>\n                    <uitk-icon name=\"calendar\"></uitk-icon>\n                </div>\n                <div class=\"input-group "
    + alias4(((helper = (helper = lookupProperty(helpers,"classes") || (depth0 != null ? lookupProperty(depth0,"classes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":27,"column":40},"end":{"line":27,"column":51}}}) : helper)))
    + " input-icon-left"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"endLabel") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":67},"end":{"line":27,"column":104}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"endLabel") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":30,"column":27}}})) != null ? stack1 : "")
    + "                    <input id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"endDateId") || (depth0 != null ? lookupProperty(depth0,"endDateId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"endDateId","hash":{},"data":data,"loc":{"start":{"line":31,"column":31},"end":{"line":31,"column":44}}}) : helper)))
    + "\" type=\"text\" class=\"cal-text-input "
    + alias4(((helper = (helper = lookupProperty(helpers,"inputClasses") || (depth0 != null ? lookupProperty(depth0,"inputClasses") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputClasses","hash":{},"data":data,"loc":{"start":{"line":31,"column":80},"end":{"line":31,"column":96}}}) : helper)))
    + "\" size=\"12\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":31,"column":114},"end":{"line":31,"column":122}}}) : helper)))
    + "_end\" placeholder=\""
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"uitk_calendar_placeholder_date",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":31,"column":141},"end":{"line":31,"column":185}}}))
    + "\"\n                        value=\""
    + alias4((lookupProperty(helpers,"i18nDate")||(depth0 && lookupProperty(depth0,"i18nDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"endDateValue") : depth0),{"name":"i18nDate","hash":{"style":"short"},"data":data,"loc":{"start":{"line":32,"column":31},"end":{"line":32,"column":70}}}))
    + "\"\n                        data-iso-date=\""
    + alias4((lookupProperty(helpers,"i18nDate")||(depth0 && lookupProperty(depth0,"i18nDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"endDateValue") : depth0),{"name":"i18nDate","hash":{"style":"iso"},"data":data,"loc":{"start":{"line":33,"column":39},"end":{"line":33,"column":76}}}))
    + "\"\n                        data-control=\"calendar\"\n                        data-first-valid-date=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"firstValidDate") || (depth0 != null ? lookupProperty(depth0,"firstValidDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"firstValidDate","hash":{},"data":data,"loc":{"start":{"line":35,"column":47},"end":{"line":35,"column":65}}}) : helper)))
    + "\"\n                        data-last-valid-date=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"lastValidDate") || (depth0 != null ? lookupProperty(depth0,"lastValidDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lastValidDate","hash":{},"data":data,"loc":{"start":{"line":36,"column":46},"end":{"line":36,"column":63}}}) : helper)))
    + "\"\n                        data-date-range=\"true\"\n                        data-start-date=\"#"
    + alias4(((helper = (helper = lookupProperty(helpers,"startDateId") || (depth0 != null ? lookupProperty(depth0,"startDateId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"startDateId","hash":{},"data":data,"loc":{"start":{"line":38,"column":42},"end":{"line":38,"column":57}}}) : helper)))
    + "\"\n                        data-default-booking-length=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bookingLength") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":39,"column":53},"end":{"line":39,"column":107}}})) != null ? stack1 : "")
    + "\"\n                        data-max-booking-length=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"maxBookingLength") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":40,"column":49},"end":{"line":40,"column":110}}})) != null ? stack1 : "")
    + "\"\n                        data-min-booking-length=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"minBookingLength") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":41,"column":49},"end":{"line":41,"column":109}}})) != null ? stack1 : "")
    + "\"\n                        data-annual=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"annual") || (depth0 != null ? lookupProperty(depth0,"annual") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"annual","hash":{},"data":data,"loc":{"start":{"line":42,"column":37},"end":{"line":42,"column":47}}}) : helper)))
    + "\"\n                        data-time-picker-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"timePickerId") || (depth0 != null ? lookupProperty(depth0,"timePickerId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"timePickerId","hash":{},"data":data,"loc":{"start":{"line":43,"column":45},"end":{"line":43,"column":61}}}) : helper)))
    + "\"\n                        data-tooltip-id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tooltipId") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":44,"column":41},"end":{"line":44,"column":101}}})) != null ? stack1 : "")
    + "\"\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"smallSreen") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":24},"end":{"line":45,"column":58}}})) != null ? stack1 : "")
    + "\n                        autocomplete=\"off\"/>\n                    <uitk-icon name=\"calendar\"></uitk-icon>\n                </div>\n                <div class=\"input-message\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"inputMsg") || (depth0 != null ? lookupProperty(depth0,"inputMsg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputMsg","hash":{},"data":data,"loc":{"start":{"line":49,"column":43},"end":{"line":49,"column":57}}}) : helper))) != null ? stack1 : "")
    + "</div>\n            </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " label-outside";
},"6":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <label>"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"startLabel") : depth0),{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":7,"column":31},"end":{"line":7,"column":53}}}))
    + "</label>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"bookingLength") || (depth0 != null ? lookupProperty(depth0,"bookingLength") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"bookingLength","hash":{},"data":data,"loc":{"start":{"line":17,"column":74},"end":{"line":17,"column":91}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    return "2";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"maxBookingLength") || (depth0 != null ? lookupProperty(depth0,"maxBookingLength") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"maxBookingLength","hash":{},"data":data,"loc":{"start":{"line":18,"column":73},"end":{"line":18,"column":93}}}) : helper)));
},"14":function(container,depth0,helpers,partials,data) {
    return "60";
},"16":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"minBookingLength") || (depth0 != null ? lookupProperty(depth0,"minBookingLength") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"minBookingLength","hash":{},"data":data,"loc":{"start":{"line":19,"column":73},"end":{"line":19,"column":93}}}) : helper)));
},"18":function(container,depth0,helpers,partials,data) {
    return "1";
},"20":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"tooltipId") || (depth0 != null ? lookupProperty(depth0,"tooltipId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tooltipId","hash":{},"data":data,"loc":{"start":{"line":22,"column":58},"end":{"line":22,"column":71}}}) : helper)));
},"22":function(container,depth0,helpers,partials,data) {
    return "calendartooltip";
},"24":function(container,depth0,helpers,partials,data) {
    return " readonly";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <label>"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"endLabel") : depth0),{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":29,"column":31},"end":{"line":29,"column":51}}}))
    + "</label>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"inline") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":54,"column":4},"end":{"line":81,"column":11}}})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div  class=\"cal-inline\">\n            <div id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":56,"column":21},"end":{"line":56,"column":27}}}) : helper)))
    + "\" data-control=\"calendar\" data-inline=\"true\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":72},"end":{"line":56,"column":137}}})) != null ? stack1 : "")
    + "></div>\n        </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-value=\""
    + container.escapeExpression((lookupProperty(helpers,"i18nDate")||(depth0 && lookupProperty(depth0,"i18nDate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"i18nDate","hash":{"style":"short"},"data":data,"loc":{"start":{"line":56,"column":97},"end":{"line":56,"column":129}}}))
    + "\"";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"input-group "
    + alias4(((helper = (helper = lookupProperty(helpers,"classes") || (depth0 != null ? lookupProperty(depth0,"classes") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"classes","hash":{},"data":data,"loc":{"start":{"line":59,"column":32},"end":{"line":59,"column":43}}}) : helper)))
    + " input-icon-left"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":59},"end":{"line":59,"column":93}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":12},"end":{"line":62,"column":19}}})) != null ? stack1 : "")
    + "            <input id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":63,"column":23},"end":{"line":63,"column":29}}}) : helper)))
    + "\" type=\"text\" class=\"cal-text-input "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"invalid") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":65},"end":{"line":63,"column":94}}})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"inputClasses") || (depth0 != null ? lookupProperty(depth0,"inputClasses") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputClasses","hash":{},"data":data,"loc":{"start":{"line":63,"column":95},"end":{"line":63,"column":111}}}) : helper)))
    + "\" name=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":63,"column":119},"end":{"line":63,"column":127}}}) : helper)))
    + "\" placeholder=\""
    + alias4((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"uitk_calendar_placeholder_date",{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":63,"column":142},"end":{"line":63,"column":186}}}))
    + "\"\n                value=\""
    + alias4((lookupProperty(helpers,"i18nDate")||(depth0 && lookupProperty(depth0,"i18nDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"i18nDate","hash":{"style":"short"},"data":data,"loc":{"start":{"line":64,"column":23},"end":{"line":64,"column":55}}}))
    + "\"\n                data-iso-date=\""
    + alias4((lookupProperty(helpers,"i18nDate")||(depth0 && lookupProperty(depth0,"i18nDate"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"i18nDate","hash":{"style":"iso"},"data":data,"loc":{"start":{"line":65,"column":31},"end":{"line":65,"column":61}}}))
    + "\"\n                data-control=\"calendar\"\n                data-first-valid-date=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"firstValidDate") : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":39},"end":{"line":67,"column":86}}})) != null ? stack1 : "")
    + "\"\n                data-last-valid-date=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"lastValidDate") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":38},"end":{"line":68,"column":83}}})) != null ? stack1 : "")
    + "\"\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"endDateId") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":69,"column":16},"end":{"line":69,"column":70}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"startDateId") : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":16},"end":{"line":70,"column":76}}})) != null ? stack1 : "")
    + "\n                data-default-booking-length=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bookingLength") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":71,"column":45},"end":{"line":71,"column":99}}})) != null ? stack1 : "")
    + "\"\n                data-max-booking-length=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"maxBookingLength") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":72,"column":41},"end":{"line":72,"column":102}}})) != null ? stack1 : "")
    + "\"\n                data-min-booking-length=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"minBookingLength") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data,"loc":{"start":{"line":73,"column":41},"end":{"line":73,"column":101}}})) != null ? stack1 : "")
    + "\"\n                data-tooltip-id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tooltipId") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":74,"column":33},"end":{"line":74,"column":93}}})) != null ? stack1 : "")
    + "\"\n                data-annual=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"annual") || (depth0 != null ? lookupProperty(depth0,"annual") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"annual","hash":{},"data":data,"loc":{"start":{"line":75,"column":29},"end":{"line":75,"column":39}}}) : helper)))
    + "\"\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"disabled") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":16},"end":{"line":76,"column":47}}})) != null ? stack1 : "")
    + "\n                autocomplete=\"off\"/>\n            <uitk-icon name=\"calendar\"></uitk-icon>\n            <div class=\"input-message\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"inputMsg") || (depth0 != null ? lookupProperty(depth0,"inputMsg") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"inputMsg","hash":{},"data":data,"loc":{"start":{"line":79,"column":39},"end":{"line":79,"column":53}}}) : helper))) != null ? stack1 : "")
    + "</div>\n        </div>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <label>"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":61,"column":23},"end":{"line":61,"column":40}}}))
    + "</label>\n";
},"35":function(container,depth0,helpers,partials,data) {
    return "invalid";
},"37":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"firstValidDate") || (depth0 != null ? lookupProperty(depth0,"firstValidDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"firstValidDate","hash":{},"data":data,"loc":{"start":{"line":67,"column":61},"end":{"line":67,"column":79}}}) : helper)));
},"39":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"lastValidDate") || (depth0 != null ? lookupProperty(depth0,"lastValidDate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"lastValidDate","hash":{},"data":data,"loc":{"start":{"line":68,"column":59},"end":{"line":68,"column":76}}}) : helper)));
},"41":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-end-date=\"#"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"endDateId") || (depth0 != null ? lookupProperty(depth0,"endDateId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"endDateId","hash":{},"data":data,"loc":{"start":{"line":69,"column":49},"end":{"line":69,"column":62}}}) : helper)))
    + "\"";
},"43":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "data-start-date=\"#"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"startDateId") || (depth0 != null ? lookupProperty(depth0,"startDateId") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"startDateId","hash":{},"data":data,"loc":{"start":{"line":70,"column":53},"end":{"line":70,"column":68}}}) : helper)))
    + "\"";
},"45":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"dateRange") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(28, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":82,"column":7}}})) != null ? stack1 : "");
},"useData":true}));