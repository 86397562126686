Handlebars.registerPartial('partials/uitk/product-tour', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"tooltip_end_tour_label") || (depth0 != null ? lookupProperty(depth0,"tooltip_end_tour_label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tooltip_end_tour_label","hash":{},"data":data,"loc":{"start":{"line":26,"column":32},"end":{"line":26,"column":58}}}) : helper)))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "aria-label=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"tooltip_finish_tour_label") || (depth0 != null ? lookupProperty(depth0,"tooltip_finish_tour_label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tooltip_finish_tour_label","hash":{},"data":data,"loc":{"start":{"line":30,"column":128},"end":{"line":30,"column":157}}}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "                                <uitk-icon class=\"padding-right-small\" name=\"success\"></uitk-icon>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                                "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"tooltip_next_button_label") || (depth0 != null ? lookupProperty(depth0,"tooltip_next_button_label") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tooltip_next_button_label","hash":{},"data":data,"loc":{"start":{"line":35,"column":32},"end":{"line":35,"column":61}}}) : helper)))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"product-tour-tool-tip\" class=\"visuallyhidden\">\n    <div class=\"tool-tip-container\">\n        <div class=\"tool-tip-icon\">\n            <uitk-icon name=\"lights\"></uitk-icon>\n        </div>\n        <div class=\"tool-tip-content\">\n            <div class=\"row padding-right-medium\">\n                <div class=\"header left\">\n                    <h4 class=\"hd\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"tooltip_header") || (depth0 != null ? lookupProperty(depth0,"tooltip_header") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tooltip_header","hash":{},"data":data,"loc":{"start":{"line":9,"column":35},"end":{"line":9,"column":53}}}) : helper)))
    + "</h4>\n                </div>\n                <div class=\"header right tool-tip-progress\">\n                    <h6 class=\"hd font-small\"> "
    + alias4(((helper = (helper = lookupProperty(helpers,"toolTipProgess") || (depth0 != null ? lookupProperty(depth0,"toolTipProgess") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"toolTipProgess","hash":{},"data":data,"loc":{"start":{"line":12,"column":47},"end":{"line":12,"column":65}}}) : helper)))
    + "</h6>\n                </div>\n            </div>\n            <div class=\"row padding-top-small padding-right-medium\">\n                <div class=\"col col-12 padding-left-small\">\n                    <p class=\"small-screen-content no-top-margin\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"tooltip_content_mobile") || (depth0 != null ? lookupProperty(depth0,"tooltip_content_mobile") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tooltip_content_mobile","hash":{},"data":data,"loc":{"start":{"line":17,"column":66},"end":{"line":17,"column":92}}}) : helper)))
    + "</p>\n                    <p class=\"large-screen-content no-top-margin\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"tooltip_content") || (depth0 != null ? lookupProperty(depth0,"tooltip_content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tooltip_content","hash":{},"data":data,"loc":{"start":{"line":18,"column":66},"end":{"line":18,"column":85}}}) : helper)))
    + "</p>\n                </div>\n            </div>\n            <div class=\"row padding-top-medium\">\n                <div class=\"col col-12 align-right\">\n                    <button class=\"btn btn-text-action btn-small end-tour-button btn-no-margin\">\n						<span class=\"btn-label btn-tour-end upper-case\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLast") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":28},"end":{"line":27,"column":39}}})) != null ? stack1 : "")
    + "                        </span>\n                    </button>\n                    <button class=\"btn btn-text-action btn-small next-button btn-no-margin no-border\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLast") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":102},"end":{"line":30,"column":165}}})) != null ? stack1 : "")
    + ">\n						<span class=\"btn-label btn-tour-next upper-case\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isLast") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":32,"column":28},"end":{"line":36,"column":35}}})) != null ? stack1 : "")
    + "                        </span>\n                    </button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true}));