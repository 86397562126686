Handlebars.registerPartial('partials/uitk/uitk-calendar-template', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "annual";
},"3":function(container,depth0,helpers,partials,data) {
    return "annual-";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <section class=\"cal-section-year\">\n            <div class=\"year-control\">\n                <button class=\"btn btn-text-action btn-med next-year\">\n                    <span class=\"btn-label\"><uitk-icon name=\"toggle0\"></uitk-icon><span class=\"visuallyhidden\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"uitk_calendar_next_month",{"name":"i18nMsg","hash":{"bundle":"uitk"},"data":data,"loc":{"start":{"line":6,"column":111},"end":{"line":6,"column":163}}}))
    + "</span></span>\n                </button>\n                <h1 class=\"font-regular year\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"year1") || (depth0 != null ? lookupProperty(depth0,"year1") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"year1","hash":{},"data":data,"loc":{"start":{"line":8,"column":46},"end":{"line":8,"column":55}}}) : helper)))
    + "</h1>\n                <button class=\"btn btn-text-sub-action btn-med prev-year\">\n                    <span class=\"btn-label\"><uitk-icon name=\"toggle180\"></uitk-icon><span class=\"visuallyhidden\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,"calendar_prev_month",{"name":"i18nMsg","hash":{"bundle":"uitk"},"data":data,"loc":{"start":{"line":10,"column":113},"end":{"line":10,"column":160}}}))
    + "</span></span>\n                </button>\n            </div>\n        </section>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button class=\"btn btn-text-sub-action btn-small prev\">\n                <span class=\"btn-label\"><span class=\"icon icon-toggle270\"></span><span class=\"visuallyhidden\">"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"calendar_prev_month",{"name":"i18nMsg","hash":{"bundle":"uitk"},"data":data,"loc":{"start":{"line":18,"column":110},"end":{"line":18,"column":157}}}))
    + "</span></span>\n            </button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <button class=\"btn btn-text-sub-action btn-small next\">\n                <span class=\"btn-label\"><span class=\"icon icon-toggle90\"></span><span class=\"visuallyhidden\">"
    + container.escapeExpression((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"calendar_next_month",{"name":"i18nMsg","hash":{"bundle":"uitk"},"data":data,"loc":{"start":{"line":23,"column":109},"end":{"line":23,"column":156}}}))
    + "</span></span>\n            </button>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"cal-timepicker\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"time") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":57,"column":16},"end":{"line":66,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/timepicker"),(depth0 != null ? lookupProperty(depth0,"time") : depth0),{"name":"partials/uitk/timepicker","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"startTime") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":20},"end":{"line":62,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"endTime") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":20},"end":{"line":65,"column":27}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/timepicker"),(depth0 != null ? lookupProperty(depth0,"startTime") : depth0),{"name":"partials/uitk/timepicker","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"partials/uitk/timepicker"),(depth0 != null ? lookupProperty(depth0,"endTime") : depth0),{"name":"partials/uitk/timepicker","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"cal "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"annual") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":16},"end":{"line":1,"column":43}}})) != null ? stack1 : "")
    + "\" data-js-theme=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"annual") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":60},"end":{"line":1,"column":88}}})) != null ? stack1 : "")
    + "calendar\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"annual") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":14,"column":11}}})) != null ? stack1 : "")
    + "    <div class=\"cal-section-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"prevButton") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":8},"end":{"line":20,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"nextButton") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + "        <section class=\"cal-section\">\n            <header>\n                <h5>\n                    <span class=\"month\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"month1") || (depth0 != null ? lookupProperty(depth0,"month1") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"month1","hash":{},"data":data,"loc":{"start":{"line":29,"column":40},"end":{"line":29,"column":50}}}) : helper)))
    + "</span>\n                    <span class=\"year\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"year1") || (depth0 != null ? lookupProperty(depth0,"year1") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year1","hash":{},"data":data,"loc":{"start":{"line":30,"column":39},"end":{"line":30,"column":48}}}) : helper)))
    + "</span>\n                </h5>\n                <ul class=\"cal-days medium\">\n                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"calDays") || (depth0 != null ? lookupProperty(depth0,"calDays") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"calDays","hash":{},"data":data,"loc":{"start":{"line":33,"column":20},"end":{"line":33,"column":33}}}) : helper))) != null ? stack1 : "")
    + "\n                </ul>\n            </header>\n            <ul class=\"cal-dates medium\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"calDates1") || (depth0 != null ? lookupProperty(depth0,"calDates1") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"calDates1","hash":{},"data":data,"loc":{"start":{"line":37,"column":16},"end":{"line":37,"column":31}}}) : helper))) != null ? stack1 : "")
    + "\n            </ul>\n        </section>\n        <hr class=\"vertical-divider "
    + alias4(((helper = (helper = lookupProperty(helpers,"newYear") || (depth0 != null ? lookupProperty(depth0,"newYear") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"newYear","hash":{},"data":data,"loc":{"start":{"line":40,"column":36},"end":{"line":40,"column":47}}}) : helper)))
    + "\">\n        <section class=\"cal-section "
    + alias4(((helper = (helper = lookupProperty(helpers,"newYear") || (depth0 != null ? lookupProperty(depth0,"newYear") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"newYear","hash":{},"data":data,"loc":{"start":{"line":41,"column":36},"end":{"line":41,"column":47}}}) : helper)))
    + "\">\n            <header>\n                <h5>\n                    <span class=\"month\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"month2") || (depth0 != null ? lookupProperty(depth0,"month2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"month2","hash":{},"data":data,"loc":{"start":{"line":44,"column":40},"end":{"line":44,"column":50}}}) : helper)))
    + "</span>\n                    <span class=\"year\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"year2") || (depth0 != null ? lookupProperty(depth0,"year2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"year2","hash":{},"data":data,"loc":{"start":{"line":45,"column":39},"end":{"line":45,"column":48}}}) : helper)))
    + "</span>\n                </h5>\n                <ul class=\"cal-days medium\">\n                    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"calDays") || (depth0 != null ? lookupProperty(depth0,"calDays") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"calDays","hash":{},"data":data,"loc":{"start":{"line":48,"column":20},"end":{"line":48,"column":33}}}) : helper))) != null ? stack1 : "")
    + "\n                </ul>\n            </header>\n            <ul class=\"cal-dates medium\">\n                "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"calDates2") || (depth0 != null ? lookupProperty(depth0,"calDates2") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"calDates2","hash":{},"data":data,"loc":{"start":{"line":52,"column":16},"end":{"line":52,"column":31}}}) : helper))) != null ? stack1 : "")
    + "\n            </ul>\n        </section>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"timepicker") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":8},"end":{"line":68,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>";
},"usePartial":true,"useData":true}));