Handlebars.registerPartial('partials/uitk/supplier-logo', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<img src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"baseUrl") || (depth0 != null ? lookupProperty(depth0,"baseUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"baseUrl","hash":{},"data":data,"loc":{"start":{"line":1,"column":10},"end":{"line":1,"column":21}}}) : helper)))
    + "/images/"
    + alias4(((helper = (helper = lookupProperty(helpers,"lob") || (depth0 != null ? lookupProperty(depth0,"lob") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lob","hash":{},"data":data,"loc":{"start":{"line":1,"column":29},"end":{"line":1,"column":36}}}) : helper)))
    + "/suppliers/"
    + alias4(((helper = (helper = lookupProperty(helpers,"code") || (depth0 != null ? lookupProperty(depth0,"code") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"code","hash":{},"data":data,"loc":{"start":{"line":1,"column":47},"end":{"line":1,"column":55}}}) : helper)))
    + "/logo.svg\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"lob") || (depth0 != null ? lookupProperty(depth0,"lob") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"lob","hash":{},"data":data,"loc":{"start":{"line":1,"column":71},"end":{"line":1,"column":78}}}) : helper)))
    + " vendor logo\">";
},"useData":true}));