Handlebars.registerPartial('partials/uitk/modal-footer', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"modal-footer-btns\">\n            <button id=\"modal-cancel\" class=\"btn btn-text-action btn-med btn-no-margin\">\n                <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"cancelText") : depth0),{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":5,"column":40},"end":{"line":5,"column":62}}}))
    + "</span>\n            </button>\n            <button id=\"modal-confirm\" class=\"btn btn-action btn-med btn-no-margin\">\n                <span class=\"btn-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"confirmText") : depth0),{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":8,"column":40},"end":{"line":8,"column":63}}}))
    + "</span>\n            </button>\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"input-group opt-out\">\n            <input id=\"modal-opt-out\" name=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":14,"column":44},"end":{"line":14,"column":50}}}) : helper)))
    + "-opt-out\" type=\"checkbox\">\n            <label for=\"modal-opt-out\" class=\"modal-opt-out-label\">"
    + alias3((lookupProperty(helpers,"i18nMsg")||(depth0 && lookupProperty(depth0,"i18nMsg"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"optOutText") : depth0),{"name":"i18nMsg","hash":{},"data":data,"loc":{"start":{"line":15,"column":67},"end":{"line":15,"column":89}}}))
    + "</label>\n        </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<footer class=\"modal-footer cf\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"confirm") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"optOut") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":17,"column":11}}})) != null ? stack1 : "")
    + "</footer>";
},"useData":true}));