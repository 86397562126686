Handlebars.registerPartial('partials/uitk/search-bar', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"search-bar\" data-control=\"search-bar\">\n    <div id=\"search-bar-display\">\n        <div id=\"search-bar-change-search\">\n            <uitk-icon name='search' class=\"search-bar-icon left\"></uitk-icon>\n            <uitk-icon name='angled-arrow-left' class=\"search-bar-icon left\"></uitk-icon>\n\n            <div id=\"search-bar-responsive-wrap-outer\" class=\"text\">\n                <h3 id=\"search-bar-content-primary\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"primary") || (depth0 != null ? lookupProperty(depth0,"primary") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"primary","hash":{},"data":data,"loc":{"start":{"line":8,"column":52},"end":{"line":8,"column":65}}}) : helper))) != null ? stack1 : "")
    + "</h3>\n                <div id=\"search-bar-responsive-wrap-inner\">\n                    <h6 id=\"search-bar-content-secondary\" class=\"text-shade1\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"secondary") || (depth0 != null ? lookupProperty(depth0,"secondary") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"secondary","hash":{},"data":data,"loc":{"start":{"line":10,"column":78},"end":{"line":10,"column":93}}}) : helper))) != null ? stack1 : "")
    + "</h6>\n                    <h6 id=\"search-bar-content-tertiary\" class=\"text-shade2\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"tertiary") || (depth0 != null ? lookupProperty(depth0,"tertiary") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tertiary","hash":{},"data":data,"loc":{"start":{"line":11,"column":77},"end":{"line":11,"column":91}}}) : helper))) != null ? stack1 : "")
    + "</h6>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div id=\"search-bar-edit\" class=\"hidden\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n</div>\n";
},"usePartial":true,"useData":true}));