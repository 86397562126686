Handlebars.registerPartial('partials/uitk/modal-alert', Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"icon-stack\"><uitk-icon class=\"modal-alert-icon\" name=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"alertIcon") || (depth0 != null ? lookupProperty(depth0,"alertIcon") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"alertIcon","hash":{},"data":data,"loc":{"start":{"line":2,"column":88},"end":{"line":2,"column":101}}}) : helper)))
    + "\"></uitk-icon></span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header class=\"modal-alert alert-text-"
    + alias4(((helper = (helper = lookupProperty(helpers,"alert") || (depth0 != null ? lookupProperty(depth0,"alert") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"alert","hash":{},"data":data,"loc":{"start":{"line":1,"column":38},"end":{"line":1,"column":47}}}) : helper)))
    + "\">\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"alertIcon") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":4},"end":{"line":2,"column":129}}})) != null ? stack1 : "")
    + "\n    <h1 class=\"modal-alert-title\" id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":3,"column":38},"end":{"line":3,"column":44}}}) : helper)))
    + "-title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"alertTitle") || (depth0 != null ? lookupProperty(depth0,"alertTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"alertTitle","hash":{},"data":data,"loc":{"start":{"line":3,"column":52},"end":{"line":3,"column":66}}}) : helper)))
    + "</h1>\n</header>";
},"useData":true}));