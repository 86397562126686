Handlebars.registerPartial('partials/uitk/box-bumper', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"box-bumper\">\n    <button class=\"btn btn-text-sub-action btn-med\" data-control=\"toggle\" data-expand-text=\"<span class='btn-label'>Show More</span>\" data-collapse-text=\"<span class='btn-label'>Show Less</span>\" data-content-id=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data,"loc":{"start":{"line":2,"column":213},"end":{"line":2,"column":219}}}) : helper)))
    + "\">\n        <span class=\"btn-label\">Show More</span>\n    </button>\n</div>\n";
},"useData":true}));