Handlebars.registerPartial('partials/uitk/favicon', Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<!-- Favicon -->\n<link rel=\"icon\" type=\"image/png\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/favicon-16x16.png\" sizes=\"16x16\">\n<link rel=\"icon\" type=\"image/png\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/favicon-32x32.png\" sizes=\"32x32\">\n<link rel=\"icon\" type=\"image/png\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/favicon-96x96.png\" sizes=\"96x96\">\n\n<!-- Apple (and others) web app icons for saving to Home screen and/or Favorites screen and other random places -->\n<meta name=\"apple-mobile-web-app-title\" content=\"Egencia\">\n<link rel=\"apple-touch-icon-precomposed\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/favicon.png\">\n<link rel=\"apple-touch-icon\" sizes=\"57x57\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/bookmark-icon-57x57.png\">\n<link rel=\"apple-touch-icon\" sizes=\"60x60\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/bookmark-icon-60x60.png\">\n<link rel=\"apple-touch-icon\" sizes=\"72x72\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/bookmark-icon-72x72.png\">\n<link rel=\"apple-touch-icon\" sizes=\"76x76\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/bookmark-icon-76x76.png\">\n<link rel=\"apple-touch-icon\" sizes=\"114x114\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/bookmark-icon-114x114.png\">\n<link rel=\"apple-touch-icon\" sizes=\"120x120\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/bookmark-icon-120x120.png\">\n<link rel=\"apple-touch-icon\" sizes=\"144x144\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/bookmark-icon-144x144.png\">\n<link rel=\"apple-touch-icon\" sizes=\"152x152\" href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/bookmark-icon-152x152.png\">\n\n<!-- Microsoft -->\n<meta name=\"application-name\" content=\"Egencia\">\n<meta name=\"msapplication-TileColor\" content=\" #152835\">\n<meta name=\"msapplication-TileImage\" content=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/bookmark-icon-144x144.png\">\n<meta name=\"msapplication-square70x70logo\" content=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/bookmark-icon-72x72.png\">\n<meta name=\"msapplication-square150x150logo\" content=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/bookmark-icon-152x152.png\">\n<meta name=\"msapplication-wide310x150logo\" content=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/bookmark-icon-152x152.png\">\n<meta name=\"msapplication-square310x310logo\" content=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"EG") : depth0)) != null ? lookupProperty(stack1,"uitkHostedAssetPath") : stack1), depth0))
    + "/images/bookmark-icon-152x152.png\">";
},"useData":true}));